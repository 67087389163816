import React, { useState } from 'react'
import { Box, Button, Tooltip, Typography } from '@material-ui/core'
import PostList from '../content/PostList'
import ContainerError from '../components/ContainerError'
import { useCustomerPostsQuery } from './operations/customer-posts.generated'
import { NetworkStatus } from '@apollo/client'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ConnectedPostDetailModal, { useOpenPostParam } from '../components/ConnectedPostDetailModal'
import { useCustomerDetailUserDataQuery } from './operations/customer-detail-user-data.generated'
import { useParams } from 'react-router-dom'
import { CustomerDetailRouteParams } from './routes'
import { AccountProductName, IgMediaPostType } from '../gql-global'
import SwitchComponent from '../components/Switch'
import { ReactComponent as InfoIcon } from '../icons/information-no-halo.svg'

function CustomerDetailPosts(): React.ReactElement {
  const { id } = useParams<CustomerDetailRouteParams>()
  const [visiblePostsOnly, setVisiblePostsOnly] = useState(true)
  // todo remove
  const { data: userData, loading: userDataLoading, error: userDataError } = useCustomerDetailUserDataQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences.selectedSocialAccountId
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }
  const {
    loading: contentLoading,
    error: contentError,
    data,
    fetchMore,
    networkStatus,
  } = useCustomerPostsQuery({
    skip: !selectedSocialAccountId || !id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      mentionsWhere: {
        unavailableMedia: visiblePostsOnly ? false : undefined,
        expiredStories: !visiblePostsOnly,
      },
      id,
      activeStoryMentionsWhere,
    },
  })

  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts.some(
    p => p === AccountProductName.Campaign,
  )
  const [, setOpenPostParam] = useOpenPostParam()

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loading = (userDataLoading || contentLoading) && !loadingMore

  const pagedResponse = data?.socialAccount?.customer?.mentions
  const posts = pagedResponse?.results
  const loadMoreContent = (): void => {
    const { cursor } = pagedResponse || {}
    if (!cursor) {
      return
    }
    fetchMore({
      variables: { cursor },
    })
  }

  const error = userDataError || contentError
  return (
    <Box display="flex" flexDirection="column">
      <Box py={4} display="flex" alignItems="center">
        <SwitchComponent checked={visiblePostsOnly} onChange={() => setVisiblePostsOnly(c => !c)} />
        <Box ml={2}>
          <Typography variant="subtitle2">Visible Posts Only</Typography>
        </Box>
        <Box ml={2} display="flex" alignItems="center">
          <Tooltip title="Expired Stories and Copyrighted Media are not displayed" placement="right">
            <InfoIcon width={16} height={16} />
          </Tooltip>
        </Box>
      </Box>
      {!error && !loading && !!posts?.length && (
        <PostList
          loading={loading}
          loadingMore={loadingMore}
          hasCampaigns={hasCampaigns}
          posts={posts}
          onClickPost={id => setOpenPostParam(id)}
        />
      )}
      {!error && !loading && !posts?.length && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoPostsImage} text="No posts yet by customer" />
        </Box>
      )}
      {error && <ContainerError text="Sorry, we had a problem loading posts." />}
      {!error && pagedResponse?.cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={loadMoreContent} disabled={loadingMore}>
            Load more
          </Button>
        </Box>
      )}
      <ConnectedPostDetailModal />
    </Box>
  )
}

export default CustomerDetailPosts
