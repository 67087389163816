import React from 'react'
import { Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import OnMentionActivityListItem from './OnMentionActivityListItem'

type MentionCustomerEvent = ConcreteEventType<'MentionCustomerEvent'>
type IGMention = Extract<MentionCustomerEvent['mention'], { __typename: 'IGMention' }>

interface MentionActivityListItemProps {
  activity: MentionCustomerEvent
  isLoudCrowdUser: boolean
}

function igMentionText(mention: IGMention): string {
  const actions = []
  if (mention.wasTagged) {
    actions.push('tagged')
  }
  if (mention.wasCaptionMentioned) {
    actions.push('mentioned')
  }

  if (mention.wasStoryMentioned) {
    actions.push('mentioned')
  }

  let actionString = actions.join(' and ')
  actionString = `${actionString[0]?.toUpperCase()}${actionString.substring(1)} you`
  const postType = mention.media?.postType
  const actionType = postType === 'STORY' ? 'story' : 'post'
  return `${actionString} in a ${actionType}`
}

function MentionActivityListItem({ activity, isLoudCrowdUser }: MentionActivityListItemProps): React.ReactElement {
  const mention = activity.mention
  return (
    <OnMentionActivityListItem
      text={
        <>
          <Typography variant="subtitle1" display="inline">
            {mention.__typename === 'IGMention' ? igMentionText(mention) : 'mentioned you in a post'}
          </Typography>
        </>
      }
      eventAt={activity.eventAt}
      mention={activity.mention}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default MentionActivityListItem
