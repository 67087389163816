import React, { ReactElement } from 'react'
import { Box, Button, Tooltip, Typography } from '@material-ui/core'
import { DiscountCodeSettingBlockType } from './DiscountSettingsBlock'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useToast } from '../../components/Alert/ToastProvider'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: '200px',
  },
  copyButton: {
    fontWeight: 'bold',
    height: theme.spacing(6),
    minWidth: theme.spacing(16),
    marginTop: '-4px',
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
  },
}))

interface SettingsBlockProps {
  blocks: DiscountCodeSettingBlockType[]
}

function SettingsBlocks({ blocks }: SettingsBlockProps): ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()

  const onCopyValue = (value: string) => {
    navigator.clipboard.writeText(value)
    showToast({ message: 'Copied to clipboard!', severity: 'info' })
  }

  return (
    <Box display="flex" padding={5} gridGap={25}>
      {blocks.map((block, idx) => (
        <Box flex={1} key={idx}>
          <Typography variant="caption">{block.title}</Typography>
          <Box display="flex" flexDirection="column" paddingLeft={3}>
            {block.items.map((item, itemIdx: number) => (
              <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop={2}>
                <Box display="flex" flexDirection="column" gridGap={5}>
                  <Typography variant="caption" color="secondary" key={itemIdx}>
                    {item.title}
                  </Typography>
                </Box>
                <Box textAlign="right" display="flex" flexDirection="column" gridGap={5}>
                  {/* only show tooltip if text is ellipsed */}
                  <Tooltip title={item.value} placement="top-start" className={classes.tooltip} arrow enterDelay={500} disableHoverListener={!item.showCopyButton}>
                    <Typography variant="caption" key={itemIdx} className={classes.textEllipsis}>
                      {item.value}
                    </Typography>
                  </Tooltip>
                </Box>
                {!!item.showCopyButton && (
                  <Box display="flex">
                    <Button
                      onClick={() => onCopyValue(item.value)}
                      variant="outlined"
                      color="primary"
                      className={classes.copyButton}
                    >
                      Copy
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SettingsBlocks
