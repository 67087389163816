import React from 'react'
import ActivityListItemContent from './ActivityListItemContent'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import { ReactComponent as TrophyIcon } from '../icons/trophy.svg'
import { ParticipantStatus } from '../gql-global'
import { isTypeName } from '../types/utility'
import StoriesAvatarV2 from '../components/CustomerAvatar/StoriesAvatarV2'

interface ProgramActivityListItemProps {
  activity: ConcreteEventType<'RegisterProgramCustomerEvent' | 'ParticipantStatusCustomerEvent'>
  isLoudCrowdUser: boolean
  avatarUrl?: string | null
  hasActiveStory?: boolean
}

function ProgramActivityListItem({
  activity,
  isLoudCrowdUser,
  avatarUrl = null,
  hasActiveStory = false,
}: ProgramActivityListItemProps): React.ReactElement {
  const campaignName = `${activity.programParticipant?.program?.name || 'the'} program`
  let actionText: string
  if (isTypeName(activity, 'RegisterProgramCustomerEvent')) {
    if (activity.actor) {
      actionText = 'Added to'
    } else if (activity.programParticipant?.program?.autoApproved) {
      actionText = 'Signed up for'
    } else {
      actionText = 'Applied for'
    }
  } else if (activity.participantStatus === ParticipantStatus.Approved) {
    actionText = 'Added to'
  } else {
    actionText = 'Rejected for'
  }
  const text = `${actionText} ${campaignName}`
  return (
    <ActivityListItemContent
      media={
        avatarUrl ? (
          <Box m={2} p={2}>
            <StoriesAvatarV2 loading={false} avatarUrl={avatarUrl} size="small" hasActiveStory={hasActiveStory} />
          </Box>
        ) : (
          <Box m={4} p={2} width={48} height={48} borderRadius={24} bgcolor="success.light" color="success.main">
            <TrophyIcon width={32} height={32} />
          </Box>
        )
      }
      text={
        <Typography variant="subtitle1" display="inline">
          {text}
        </Typography>
      }
      eventAt={activity.eventAt}
      actorName={activity.actor?.email || undefined}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default ProgramActivityListItem
