import React from 'react'
import { Box, Button, makeStyles, Theme } from '@material-ui/core'
import ContainerError from '../components/ContainerError'
import { ApolloError, NetworkStatus } from '@apollo/client'
import { ReactComponent as NoActivityImage } from '../images/no-activities.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import { useParams } from 'react-router-dom'
import { CustomerDetailRouteParams } from './routes'
import ActivityListItem from './ActivityListItem'
import ConnectedPostDetailModal from '../components/ConnectedPostDetailModal'
import ActivityListItemLoading from './ActivityListItemLoading'
import { FbUser, IgMediaPostType } from '../gql-global'
import { useCustomerActivitiesQuery } from './operations/customer-activities.generated'

export const LOADING_BATCH_SIZE = 12

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}))

interface ActivityFeedProps {
  selectedSocialAccountId?: string | null
  isLoudCrowdUser: boolean
  userDataLoading?: boolean
  userDataError?: ApolloError
  customerUsername: string
}

function CustomerDetailActivityFeed({
  selectedSocialAccountId,
  isLoudCrowdUser,
  userDataLoading,
  userDataError,
  customerUsername,
}: ActivityFeedProps): React.ReactElement {
  const classes = useStyles()
  const { id } = useParams<CustomerDetailRouteParams>()

  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data,
    fetchMore,
    networkStatus,
  } = useCustomerActivitiesQuery({
    skip: !selectedSocialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      id,
      limit: LOADING_BATCH_SIZE,
      activeStoryMentionsWhere,
    },
  })

  const customerFbUsers: FbUser[] = []
  data?.socialAccount?.customer?.igUser?.storiesIgSocialAccounts?.forEach(s => {
    if (s.fbUsers) {
      customerFbUsers.push(...s.fbUsers)
    }
  })

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loading = (userDataLoading || activitiesLoading) && !loadingMore

  const pagedResponse = data?.socialAccount?.customer?.events
  const activities = pagedResponse?.results
  const loadMoreActivities = (): void => {
    if (data?.socialAccount?.customer?.events?.cursor) {
      fetchMore({
        variables: {
          cursor: data?.socialAccount?.customer?.events?.cursor,
          limit: LOADING_BATCH_SIZE,
        },
      })
    }
  }

  const error = userDataError || activitiesError
  return (
    <Box>
      {!error && !loading && !!activities?.length && (
        <div className={classes.list}>
          {activities.map(a => (
            <ActivityListItem
              key={a.id}
              customerId={id}
              customerUsername={customerUsername}
              activity={a}
              avatarUrl={data?.socialAccount?.customer?.igUser?.avatarUrl}
              hasActiveStory={!!data?.socialAccount?.customer?.activeStoryMentionsStats?.postCount}
              isLoudCrowdUser={isLoudCrowdUser}
            />
          ))}
        </div>
      )}
      {!error && (loading || loadingMore) && (
        <div className={classes.list}>
          {new Array(LOADING_BATCH_SIZE).fill(null).map((_, i) => (
            <ActivityListItemLoading key={i} />
          ))}
        </div>
      )}
      {!error && !loading && !activities?.length && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoActivityImage} text="No activities yet for this customer" />
        </Box>
      )}
      {error && <ContainerError text="Sorry, we had a problem loading activities." />}
      {!error && pagedResponse?.cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={loadMoreActivities} disabled={loadingMore}>
            Load more
          </Button>
        </Box>
      )}
      <ConnectedPostDetailModal />
    </Box>
  )
}

export default CustomerDetailActivityFeed
