import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import LabelChip from '../components/labelChip'
import OnMentionActivityListItem from './OnMentionActivityListItem'
import { isTypeName } from '../types/utility'

interface LabelActivityListItemProps {
  activity: ConcreteEventType<'AddLabelCustomerEvent' | 'RemoveLabelCustomerEvent'>
  isLoudCrowdUser: boolean
}

function LabelActivityListItem({ activity, isLoudCrowdUser }: LabelActivityListItemProps): React.ReactElement {
  return (
    <OnMentionActivityListItem
      text={
        <>
          <Typography variant="subtitle1" display="inline">
            {isTypeName(activity, 'AddLabelCustomerEvent') ? 'Added' : 'Removed'} label
          </Typography>
          <Box ml={1} display="inline">
            <LabelChip label={activity.label.name} />
          </Box>
        </>
      }
      eventAt={activity.eventAt}
      mention={activity.mention}
      actorName={activity.actor?.email || 'LoudCrowd'}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default LabelActivityListItem
