import React from 'react'
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import ActivityListItemContent from './ActivityListItemContent'
import { ReactComponent as CommissionIcon } from '../icons/commission.svg'
import IntegrationLogo from '../components/IntegrationLogo'
import { IntegrationType } from '../gql-global'

const useStyles = makeStyles(() =>
  createStyles({
    tremendousLogo: {
      width: '100px',
    },
  }),
)

interface CommissionsPaidActivityListItemProps {
  activity: ConcreteEventType<
    'CommissionSuccessCustomerEvent' | 'CommissionFailedCustomerEvent' | 'CommissionPaymentCustomerEvent'
  >
  isLoudCrowdUser: boolean
  failed: boolean
}

function CommissionsActivityListItem({
  activity,
  isLoudCrowdUser,
  failed,
}: CommissionsPaidActivityListItemProps): React.ReactElement {
  const classes = useStyles()
  const text = `Commissions ${failed ? 'failed' : 'paid'} - Shoppable Ambassador Program Commissions - `

  return (
    <ActivityListItemContent
      media={
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent={'center'}
          m={4}
          p={2}
          width={48}
          height={48}
          borderRadius={24}
          bgcolor="success.light"
          color="success.main"
        >
          <CommissionIcon width={25} height={25} />
        </Box>
      }
      text={
        <Typography variant="subtitle1" display="inline">
          {text}
          <IntegrationLogo integrationType={IntegrationType.Tremendous} className={classes.tremendousLogo} />
        </Typography>
      }
      eventAt={activity.eventAt}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default CommissionsActivityListItem
