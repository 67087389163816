import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import OnMentionActivityListItem from './OnMentionActivityListItem'
import TagStatusChip from '../components/PostCard/TagStatusChip'

interface MentionStatusActivityListItemProps {
  activity: ConcreteEventType<'MentionStatusCustomerEvent'>
  isLoudCrowdUser: boolean
}

function MentionStatusActivityListItem({
  activity,
  isLoudCrowdUser,
}: MentionStatusActivityListItemProps): React.ReactElement {
  return (
    <OnMentionActivityListItem
      mention={activity.mentionHistory.mention}
      text={
        <>
          <Box mr={2}>
            <Typography variant="subtitle1" display="inline">
              Post status changed to
            </Typography>{' '}
          </Box>
          {activity.mentionHistory.newStatus && <TagStatusChip status={activity.mentionHistory.newStatus} />}
        </>
      }
      eventAt={activity.eventAt}
      actorName={activity.actor?.email}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default MentionStatusActivityListItem
