import React from 'react'
import { Box, Button, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import { ReactComponent as InformationIcon } from '../icons/information-with-halo-cyan.svg'
import { ReactComponent as FBIcon } from '../icons/fb.svg'
import facebookSDK from '../facebook'
import { PICK_SOCIAL_ACCOUNT_ROUTE } from './routes'
import { useHistory } from 'react-router-dom'
import { useToast } from '../components/Alert/ToastProvider'
import { FACEBOOK_CONFIG_ID } from '../facebook/constants'
import { AuthFbUserMutation, useAuthFbUserMutation } from './operations/auth-fb-user.generated'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: { fontWeight: 600 },

    roundedRectangleButton: {
      borderRadius: 4,
      marginTop: theme.spacing(5),
    },
    bulletlist: {
      marginBlockStart: 'unset',
      marginBlockEnd: 'unset',
    },
  }),
)

export type FBUserAuthResult = NonNullable<NonNullable<AuthFbUserMutation['authFbBusiness']>['fbUser']>

interface ConnectFacebookProps {
  onFbAuthed(result: FBUserAuthResult | 'error'): void
}

function ConnectFacebook({ onFbAuthed }: ConnectFacebookProps): React.ReactElement {
  const styles = useStyles()
  const { showToast } = useToast()
  const history = useHistory()

  const [authFbUser] = useAuthFbUserMutation()

  async function getFBUser() {
    const authResponse = await facebookSDK.authForBusiness(FACEBOOK_CONFIG_ID)
    if (
      authResponse.status !== 'connected' ||
      !(authResponse.authResponse.code || authResponse.authResponse.accessToken)
    ) {
      // error for not connected
      showToast({
        severity: 'error',
        title: 'Facebook Connection Failed',
        message:
          'Something went wrong, either some permissions were not granted, or there was a network error. Please try again.',
      })
    } else {
      const result = await authFbUser({
        variables: { code: authResponse.authResponse.code, token: authResponse.authResponse.accessToken },
      })
      if (!result.data?.authFbBusiness?.fbUser) {
        showToast({
          severity: 'error',
          title: 'Facebook Connection Failed',
          message:
            'Something went wrong, either some permissions were not granted, or there was a network error. Please try again.',
        })
      } else {
        if (!result.data?.authFbBusiness?.fbUser?.availableIgAccounts) {
          onFbAuthed('error')
          history.push(PICK_SOCIAL_ACCOUNT_ROUTE.path)
        } else {
          onFbAuthed(result.data.authFbBusiness.fbUser)
          history.push(PICK_SOCIAL_ACCOUNT_ROUTE.path)
        }
      }
    }
  }

  return (
    <Box width={484} margin="auto">
      <Box display="flex" flexDirection="column" alignItems="center" pt={27}>
        <Typography className={styles.bold} variant="h4">
          Connect to Facebook
        </Typography>
        <Box mt={2} mb={6.5} textAlign="center">
          <Typography variant="body1">
            Granting access will unlock all of LoudCrowd's features. Don't worry. We won't post on your behalf.
          </Typography>
        </Box>
      </Box>
      <Button
        className={styles.roundedRectangleButton}
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<FBIcon />}
        onClick={getFBUser}
      >
        Connect to Facebook
      </Button>
      <Box display="flex" mt={14}>
        <Box>
          <InformationIcon width={48} />
        </Box>
        <Box>
          <ul className={styles.bulletlist}>
            <li>Must have a Facebook Business Account connected to an Instagram to connect.</li>
            <li>LoudCrowd does NOT post or comment on your behalf.</li>
            <li>LoudCrowd connection is secure and encrypted.</li>
            <li>
              <Link href="https://www.loudcrowd.com/privacy-policy-dpf" target="_blank" rel="noopener noreferrer">
                LoudCrowd Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                href="https://help.loudcrowd.com/en/articles/5647889-loudcrowd-sign-up-troubleshooting-your-facebook-authentication"
                target="_blank"
                rel="noopener noreferrer"
              >
                Troubleshooting your Facebook Connection
              </Link>
            </li>
            <li>
              <Link
                href="https://help.loudcrowd.com/en/articles/5647919-why-do-we-need-the-requested-facebook-permissions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Why do we need those Facebook permissions
              </Link>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  )
}

export default ConnectFacebook
