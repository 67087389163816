import React from 'react'
import ActivityListItemContent from './ActivityListItemContent'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import { ReactComponent as StorefrontIcon } from '../icons/storefront.svg'

interface ManagedModeLoginActivityListItemProps {
  activity: ConcreteEventType<'ManagedModeLoginCustomerEvent'>
  isLoudCrowdUser: boolean
}

function ManagedModeLoginActivityListItem({
  activity,
  isLoudCrowdUser,
}: ManagedModeLoginActivityListItemProps): React.ReactElement {
  return (
    <ActivityListItemContent
      media={
        <Box
          m={4}
          p={1}
          width={48}
          height={48}
          borderRadius={24}
          style={{ border: 'solid #5500FF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <StorefrontIcon width={44} height={44} />
        </Box>
      }
      text={
        <Typography variant="subtitle1" display="inline">
          Admin logged into Manage Mode on Storefront page
        </Typography>
      }
      eventAt={activity.eventAt}
      actorName={activity.actor?.email || undefined}
      isLoudCrowdUser={isLoudCrowdUser}
    />
  )
}

export default ManagedModeLoginActivityListItem
