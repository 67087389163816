import React from 'react'
import { Event } from './activity-types'
import MentionActivityListItem from './MentionActivityListItem'
import FulfilledTierActivityListItem from './FullfilledtierActivityListItem'
import SegmentActivityListItem from './SegmentActivityListItem'
import ProgramActivityListItem from './ProgramActivityListItem'
import MentionStatusActivityListItem from './MentionStatusActivityListItem'
import LabelActivityListItem from './LabelActivityListItem'
import CustomerNoteActivityListItem from './CustomerNoteActivityListItem'
import MessageActivityListItem from './MessageActivityListItem'
import ChallengeApprovalStatusActivityListItem from './ChallengeApprovalStatusActivityListItem'
import OrderActivityListItem from './OrderActivityListItem'
import AttributedSaleActivityListItem from './AttributedSaleActivityListItem'
import CommissionsActivityListItem from './CommissionsActivityListItem'
import SetCommissionTierActivityListItem from './SetCommissionTierActivityListItem'
import ManagedModeLoginActivityListItem from './ManagedModeLoginActivityListItem'

interface ActivityListItemProps {
  activity: Event
  isLoudCrowdUser: boolean
  avatarUrl?: string | null
  customerId?: string
  hasActiveStory?: boolean
  customerUsername: string
}

function ActivityListItem({
  activity,
  isLoudCrowdUser,
  avatarUrl,
  customerId,
  hasActiveStory,
  customerUsername,
}: ActivityListItemProps): React.ReactElement | null {
  const { id } = activity
  switch (activity.__typename) {
    case 'MentionCustomerEvent':
      return <MentionActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'FulfilledTierCustomerEvent':
      return <FulfilledTierActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'AddSegmentCustomerEvent':
    case 'RemoveSegmentCustomerEvent':
      return <SegmentActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'AddCustomerNoteEvent':
    case 'RemoveCustomerNoteEvent':
      return (
        <CustomerNoteActivityListItem
          key={id}
          activity={activity}
          isLoudCrowdUser={isLoudCrowdUser}
          customerId={customerId}
        />
      )
    case 'AddLabelCustomerEvent':
    case 'RemoveLabelCustomerEvent':
      return <LabelActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'RegisterProgramCustomerEvent':
    case 'ParticipantStatusCustomerEvent':
      return (
        <ProgramActivityListItem
          key={id}
          activity={activity}
          isLoudCrowdUser={isLoudCrowdUser}
          avatarUrl={avatarUrl}
          hasActiveStory={hasActiveStory}
        />
      )
    case 'MentionStatusCustomerEvent':
      return <MentionStatusActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'SentMessageCustomerEvent':
      return <MessageActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'ChallengeApprovalStatusCustomerEvent':
      return <ChallengeApprovalStatusActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'PlacedOrderCustomerEvent':
      return (
        <OrderActivityListItem
          key={id}
          activity={activity}
          isLoudCrowdUser={isLoudCrowdUser}
          customerUsername={customerUsername}
        />
      )
    case 'AttributedSaleCustomerEvent':
      return (
        <AttributedSaleActivityListItem
          key={id}
          activity={activity}
          isLoudCrowdUser={isLoudCrowdUser}
          customerUsername={customerUsername}
        />
      )
    case 'CommissionPaymentCustomerEvent':
      // DEPRECATED
      return (
        <CommissionsActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} failed={false} />
      )
    case 'CommissionSuccessCustomerEvent':
      return (
        <CommissionsActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} failed={false} />
      )
    case 'CommissionFailedCustomerEvent':
      return (
        <CommissionsActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} failed={true} />
      )
    case 'SetCommissionTierCustomerEvent':
      return <SetCommissionTierActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    case 'ManagedModeLoginCustomerEvent':
      return <ManagedModeLoginActivityListItem key={id} activity={activity} isLoudCrowdUser={isLoudCrowdUser} />
    default:
      return null
  }
}

export default ActivityListItem
