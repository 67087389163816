import React, { useState } from 'react'
import { Menu, Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import { useRef } from "react"
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import SelectionPickerMenuContent from '../../components/SelectionPicker'

const useStyles = makeStyles(({ typography, palette, spacing }: Theme) =>
  createStyles({
    h7: {
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    filterButton: {
      margin: '0px 8px 8px 0px',
    },
  }),
)

interface CampaignRevenueDashboardFiltersProps {
  options: readonly { id: 'tax' | 'shipping' | 'returns'; label: string }[]
  selectedOptions: Set<'tax' | 'shipping' | 'returns'>
  setSelectedOptions: (options: Set<'tax' | 'shipping' | 'returns'>) => void
}

const CampaignRevenueDashboardFilters: React.FC<CampaignRevenueDashboardFiltersProps> = props => {
  const { options, selectedOptions, setSelectedOptions } = props
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const handleSetSelectedOptions = (value: Set<'tax' | 'shipping' | 'returns'>) => {
    setSelectedOptions(value)
  }

  const getButtonLabel = (selectedOptions: Set<'tax' | 'shipping' | 'returns'>) => {
    if (selectedOptions.size === 3) {
      return 'All Revenue'
    }

    const excludedRevenueList = ['Tax', 'Shipping', 'Returns'].filter(revenue => !selectedOptions.has(revenue.toLowerCase() as 'tax' | 'shipping' | 'returns'))
    return `Revenue Excluding ${excludedRevenueList.join(', ')}`
  }

  const buttonLabel = getButtonLabel(selectedOptions)


  return (
    <Box>
      <Button
        color={'primary'}
        data-intercom-target="Revenue Dashboard Filters"
        variant="outlined"
        ref={buttonRef}
        onClick={() => setOpen(true)}
        endIcon={<ChevronDownIcon height="16px" width="16px" />}
        className={classes.filterButton}
      >
        <Typography className={classes.h7}>
          {buttonLabel}
        </Typography>
      </Button>

      <Menu
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setOpen(false)}
      >
        <SelectionPickerMenuContent
          onSelectValue={(value: Set<'tax' | 'shipping' | 'returns'>) => handleSetSelectedOptions(value)}
          value={selectedOptions}
          setPickerOpen={setOpen}
          option={{
            name: 'revenueFilters',
            type: 'selection',
            label: 'Revenue Filters',
            selectionOptions: options,
          }}
          resetValues={new Set<'tax' | 'shipping' | 'returns'>(['tax', 'shipping', 'returns'])}
          selectionRequired={false}
        />
      </Menu>
    </Box>

  )
}

export default CampaignRevenueDashboardFilters
